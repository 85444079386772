<template>
  <div class="industry">
    <div class="industry-second">
      <div class="industry-second-up">
        <template>
          <a-form
            class="ant-advanced-search-form"
            :form="form"
            @submit="handleSearch"
          >
            <a-row :gutter="24">
              <a-col :span="6">
                <!--<a-form-item label="行政区划：">-->
                <!--<a-select-->
                <!--mode="multiple"-->
                <!--style="width: 100%"-->
                <!--placeholder="请选择"-->
                <!--@change="handleChangeArea"-->
                <!--v-model="param.selectedAreaId"-->
                <!--&gt;-->
                <!--<a-select-option-->
                <!--:value="item.id"-->
                <!--v-for="(item, index) in xzqh"-->
                <!--:key="index"-->
                <!--&gt;-->
                <!--{{ item.name }}-->
                <!--</a-select-option>-->
                <!--</a-select>-->
                <!--</a-form-item>-->
                <a-form-item label="行政区划：">
                  <a-cascader
                    :options="options"
                    @change="areaChange"
                    placeholder="请选择"
                    expandTrigger="hover"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="7">
                <a-form-item label="水样类型：">
                  <a-select
                    mode="multiple"
                    style="width: 100%"
                    placeholder="请选择"
                    v-model="param.sampleType"
                    @change="handleChangeSampleType"
                  >
                    <a-select-option
                      :value="item.value"
                      v-for="(item, index) in sampleTypes"
                      :key="index"
                    >
                      {{ item.description }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="年份：">
                  <a-select
                    style="width: 100%"
                    placeholder="请选择"
                    v-model="param.reportYear"
                    @change="handleChangeYear"
                  >
                    <a-select-option v-for="i in year" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="">
                  <a-select
                    mode="multiple"
                    style="width: 100%"
                    placeholder="请选择"
                    @change="handleChangeSeason"
                    v-model="param.season"
                  >
                    <a-select-option
                      :value="item.value"
                      v-for="(item, index) in seasons"
                      :key="index"
                    >
                      {{ item.description }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row style="margin-top:20px" :gutter="24">
              <a-col :span="6">
                <a-form-item label="是否合格：">
                  <a-select
                    mode="multiple"
                    style="width: 100%"
                    placeholder="请选择"
                    @change="handleChangeisStandard"
                    v-model="param.isStandard"
                  >
                    <a-select-option
                      :value="item.value"
                      v-for="(item, index) in isStandards"
                      :key="index"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="11">
                <a-form-item label="集中供水工程：">
                  <a-input
                    v-model="param.projectName"
                    placeholder="请输入工程名称"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="7" :style="{ textAlign: 'right' }">
                <a-button type="primary" html-type="submit">
                  查询
                </a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                  重置
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </template>
      </div>
      <div class="industry-second-down">
        <!-- 表格上面内容 -->
        <div class="detail-top flex justify-between align-center">
          <div class="detail-top-left flex align-center">
            <div class="detail-top-left-shu"></div>
            <div class="detail-top-left-text">检测报告</div>
          </div>
          <div class="detail-top-right flex align-center">
            <a-button type="primary">
              导出
            </a-button>
          </div>
        </div>
        <!-- 表格 -->
        <div class="detail-table">
          <div class="detail-table-con">
            <div class="detail-table-content">
              <div class="detail-table-content-top flex align-center ">
                <div
                  class="detail-table-content-itemA width90 flex align-center justify-center"
                  :class="index == 8 ? 'width300' : ''"
                  v-for="(item, index) in columns"
                  :key="index"
                >
                  <div class="detail-table-content-itemA-con">
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <div
                class="detail-table-content-list flex align-center"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="detail-table-content-list-item width90  flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ (current - 1) * 20 + index + 1 }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.province }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.city }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.country }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.projectName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.cwsLoc }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.season }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item flex align-center justify-center width90"
                  @click="toDetail(item.id)"
                >
                  <div class="detail-table-content-list-item-text">
                    <u>{{ item.reportNo }}</u>
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width300  flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.realCenterName }}
                  </div>
                </div>
                <!-- <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.num }}
                  </div>
                </div> -->
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.projectTypeName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    <!-- {{ item.factoryNum }} -->
                    {{ item.detectsNum }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.result }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{
                      item.sourceDetectsNum +
                        item.factoryDetectsNum +
                        item.tailDetectsNum
                    }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.sourceDetects }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.sampleDate }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.detectionDate }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.detectionDate }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.sampleName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.detectionName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.acceptName }}
                  </div>
                </div>
                <div
                  class="detail-table-content-list-item width90 flex align-center justify-center"
                >
                  <div class="detail-table-content-list-item-text">
                    {{ item.verifyName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-table-page flex align-center">
          <template>
            <a-pagination
              :current="current"
              :total="total"
              @change="onChange"
              show-size-changer
              @showSizeChange="onShowSizeChange"
              :page-size="pageSize"
              :pageSizeOptions="pageSizeOptions"
            >
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template></a-pagination
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "no",
    key: "no",
    width: 120
  },
  {
    title: "省名称",
    dataIndex: "province",
    key: "province",
    width: 120
  },
  {
    title: "市名称",
    dataIndex: "city",
    key: "city",
    width: 120
  },
  {
    title: "县名称",
    dataIndex: "country",
    key: "country",
    width: 120
  },
  {
    title: "集中供水工程名称",
    dataIndex: "num6",
    key: "num6",
    width: 105
  },
  {
    title: "所在位置",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "检测季节",
    dataIndex: "num",
    key: "num 1",
    width: 103
  },
  {
    title: "检测报告编号",
    dataIndex: "num1",
    key: "num1",
    width: 90
  },
  {
    title: "检测单位名称",
    dataIndex: "num2",
    key: "num2",
    width: 103
  },
  // {
  //   title: "检测单位类型",
  //   dataIndex: "num3",
  //   key: "num3",
  //   width: 103
  // },
  {
    title: "供水工程类型",
    dataIndex: "num4",
    key: "num4",
    width: 90
  },
  {
    title: "水样数量（件）",
    dataIndex: "num11",
    key: "num11",
    width: 123
  },
  {
    title: "检测结果",
    dataIndex: "num12",
    key: "num12",
    width: 90
  },
  {
    title: "超标项数（项）",
    dataIndex: "num13",
    key: "num13",
    width: 105
  },
  {
    title: "超标指标",
    dataIndex: "num14",
    key: "num14",
    width: 105
  },
  {
    title: "采样日期",
    dataIndex: "num19",
    key: "num14",
    width: 105
  },
  {
    title: "检测日期",
    dataIndex: "num21",
    key: "num14",
    width: 105
  },
  {
    title: "编制日期",
    dataIndex: "num22",
    key: "num14",
    width: 105
  },
  {
    title: "采样人员",
    dataIndex: "num23",
    key: "num14",
    width: 105
  },
  {
    title: "编制人员",
    dataIndex: "num25",
    key: "num14",
    width: 105
  },
  {
    title: "批准人员",
    dataIndex: "num26",
    key: "num14",
    width: 105
  },
  {
    title: "审核人员",
    dataIndex: "num24",
    key: "num14",
    width: 105
  }
  // {
  //   title: "上报日期",
  //   dataIndex: "num27",
  //   key: "num14",
  //   width: 105
  // }
];
// 引人组件
import { getReportList } from "@/api/water/report";
import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import { getAreaTreeList } from "@/api/basic/common";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      xzqh: ["江苏省", "北京市", "山西省"], //测试数据/行政区划
      year: [], //测试数据/年度
      sampleTypes: [],
      seasons: [],
      isStandards: [
        { value: "1", label: "合格" },
        { value: "0", label: "不合格" }
      ], //切换工程运行管理单位index
      // 筛选条件
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        sampleType: [],
        reportYear: undefined,
        season: [],
        projectName: "", //集中供水工程
        isStandard: undefined
      },
      tabListd: [
        "全部",
        "县级水务局",
        "县级供水管理总站",
        "片区或流域等县级派生水管站（所）",
        "乡镇政府或水管站",
        "国有公司",
        "自来水公司",
        "股份制公司",
        "民营公司",
        "外资公司",
        "私人租赁或承包",
        "供水协会",
        "合作组织或用水户协会",
        "村委会",
        "其他"
      ], //工程运行管理单位
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      userAreaId: {
        id: 1
      },
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      list: [],
      columns,

      current: 1, //分页页码
      total: 0, //总数
      pageSize: 20,
      pageSizeOptions: ["20", "30", "40", "50"]
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
    this.getAreaList();
  },
  mounted() {},
  methods: {
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.getReports();
    },
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
      console.log(value);
    },
    async getData() {
      await baseMenuList({
        menu: "水样类型"
      }).then(res => {
        // console.log('水样类型')
        // console.log(res)
        this.sampleTypes = res.data.list;
      });
      await baseMenuList({
        menu: "供水季节"
      }).then(res => {
        // console.log('工程运行管理单位')
        // console.log(res)
        this.seasons = res.data.list;
        // this.param.season = [this.seasons[0]?.value];
      });
      this.getReports();
    },
    getAreaList() {
      let data = getDefaultArea();
      this.param.defaultAreaId = data.id;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaId
      }).then(res => {
        if (res.code == 200) {
          this.xzqh = res.data.list;
        }
        this.getData();
      });
    },
    getReports() {
      this.data = [];
      getReportList({
        areaId: this.param.areaId,
        reportYear: this.param.reportYear,
        sampleType: this.param.sampleType,
        season: this.param.season,
        projectId: "",
        qualified: this.param.isStandard,
        projectName: this.param.projectName,
        pageNumber: this.current,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.list = res.data.list;
        }
      });
    },
    //跳转
    toDetail(id) {
      // this.$router.push({
      //   path: "/index/waterQuality/index/testReportDetail",
      //   query: {
      //     id: id
      //   }
      // });
      var url = `/test/report/view?reportId=${id}`;
      window.open(url, "_blank");
    },
    onChange(current) {
      this.current = current;
      this.getReports();
    },

    //查询
    handleSearch(e) {
      e.preventDefault();
      this.current = 1;
      this.getReports();
    },
    //清除
    handleReset() {
      this.param.waterTypes = [];
      this.param.areaId = this.param.defaultAreaId;
      this.param.selectedAreaId = undefined;
      this.current = 1;
      this.getReports();
    },
    //多选运行管理单位
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    handleChangeArea(value) {
      this.param.areaId = value;
      this.param.selectedAreaId = value;
    },
    handleChangeSampleType(value) {
      this.param.sampleType = value;
    },
    handleChangeSeason(value) {
      this.param.season = value;
    },
    handleChangeisStandard(value) {
      console.log(value);
      this.param.isStandard = value;
    },
    handleChangeYear(value) {
      this.param.reportYear = value;
    }
  }
};
</script>

<style scoped lang="less">
.detail-top {
  width: 100%;
  padding: 20px 0;
}
.detail-top-left-shu {
  width: 4px;
  height: 16px;
  background: #1890ff;
  border-radius: 2px;
  margin-right: 8px;
}
.detail-top-left-text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #28282c;
}
.detail-top-right-left {
  margin-right: 21px;
}
.industry-second {
  width: 100%;
  .industry-second-up {
    padding: 1px;
    box-sizing: border-box;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.more {
  cursor: pointer;
}
.ant-advanced-search-form-top {
  background: #ffffff;
  width: 100%;
  // height: 86px;
  padding: 25px 0px;
  margin: 0px !important;
  display: flex !important;
  align-items: center;
}
.ant-row .ant-form-item {
  margin: 0px !important;
}
.ant-advanced-search-form-bottom-all {
  background: #ffffff;
  width: 100%;
  padding: 30px 20px 36px;
  box-sizing: border-box;
  margin-top: 20px;
}
.ant-advanced-search-form-bottom {
  width: 100%;
}
.industry-second-down {
  width: 100%;
  padding: 1px 20px 20px;
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 20px;
}
.industry-second-down-top {
  padding: 20px 0px;
  justify-content: flex-end;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.industry-second-down-table-body {
  overflow: auto;
}
.industry-second-down-table {
  width: max-content !important;
}
.industry-second {
  width: 100%;
  .industry-second-up {
    padding: 1px;
    box-sizing: border-box;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.more {
  cursor: pointer;
}
.ant-advanced-search-form {
  background: #ffffff;
  width: 100%;
  padding: 25px;
  margin: 0px !important;
}
.ant-advanced-search-form-top {
  background: #ffffff;
  width: 100%;
  // height: 86px;
  padding: 25px 0px;
  margin: 0px !important;
  display: flex !important;
  align-items: center;
}
.ant-row .ant-form-item {
  margin: 0px !important;
}
.ant-advanced-search-form-bottom-all {
  background: #ffffff;
  width: 100%;
  padding: 30px 20px 36px;
  box-sizing: border-box;
  margin-top: 20px;
}
.ant-advanced-search-form-bottom {
  width: 100%;
}
.industry-second-down {
  width: 100%;
  padding: 1px 20px 20px;
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 20px;
}
.industry-second-down-top {
  padding: 20px 0px;
  justify-content: flex-end;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.industry-second-down-table-body {
  overflow: auto;
}
.industry-second-down-table {
  width: max-content !important;
}

.detail-table {
  // padding: 52px 0px 0px 0px;
  width: 100%;
  // height: 700px;
  overflow: auto;
}
.detail-table-content {
  width: max-content;
  border-top: 1px solid #e1e1ee;
  border-left: 1px solid #e1e1ee;
}
.detail-table-content-top {
}
.table-active {
  top: -32px;
  left: 30px;
  width: 20px;
  height: 20px;
}
.table-activeB {
  top: -32px;
  left: 47%;
  width: 20px;
  height: 20px;
}
.width80 {
  width: 80px;
}
.width90 {
  width: 150px;
}
.width100 {
  width: 100px;
}
.width120 {
  width: 120px;
  height: 60px;
}
.width300 {
  width: 300px;
}

.detail-table-content-itemA {
  height: 60px;
  background: #fafafa;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  // padding: 0px 10px;
  padding: 0px 8px;

  box-sizing: border-box;
}
.detail-table-content-itemA-con {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 18px;
}
.detail-table-content-itemB {
  height: 120px;
  background: #fafafa;
  box-sizing: border-box;
}
.detail-table-content-itemB-top {
  height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 50px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
}
.detail-table-content-itemB-bottom-item {
  width: 80px;
  height: 70px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
}
.detail-table-content-itemB-bottom-item-text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.detail-table-content-list:hover {
  background: rgb(230, 247, 255);
}
.detail-table-content-list-item {
  height: 40px;
  text-align: center;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.detail-table-content-list-item .detail-table-content-list-item-text u {
  text-decoration: underline;
  cursor: pointer;
}
.detail-table-page {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>

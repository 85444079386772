import http from "@/plugins/httpss";
// 检测报告：列表
export function getReportList(data) {
  return http({
    url: `/api/water-quality/qualityReportList/page`,
    params:data,
    method: "GET"
  });
}
// 检测报告：区域比较
export function getReportDetail(data) {
  return http({
    url: `/api/water-quality/qualityReportList/getDetail`,
    params:data,
    method: "GET"
  });
}